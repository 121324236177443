import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { userSelector } from "../redux/reducers/userSlice";
import { setAuthFallbackLink } from "../redux/reducers/globalSlice";
import React from "react";

interface Props {
  children: JSX.Element; // Expect children to be JSX elements
}

export default function AuthGuard({ children }: Props) {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);

  useLayoutEffect(() => {
    if (!user.token) {
      const loc = window.location;
      // Save the current location to redirect back after login
      dispatch(setAuthFallbackLink(loc.pathname + loc.search + loc.hash));
    }
  }, [user.token, dispatch]);

  // If user has token, render the children (protected routes), otherwise redirect to /login
  return user.token ? children : <Navigate to="/login" replace />;
}
