import React from "react";
import ReactDOM from "react-dom/client";
import { StrictMode } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./styles/globals.css";
import { AppRoutes } from "./app/AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const rootElement = document.getElementById("root");

const Index = () => (
  <StrictMode>
    <Provider store={store}>
      <AppRoutes />
      <ToastContainer />
    </Provider>
  </StrictMode>
);

const root = ReactDOM.createRoot(rootElement as HTMLElement);
root.render(<Index />);
