import axios from "axios";
import { useNavigate } from "react-router-dom";
import store from "../redux/store";
import { clearUserData } from "../redux/reducers/userSlice";

// Create an Axios instance with a base URL
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL || "http://localhost:5000/api",
});

// Intercept requests to add token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getState().user.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Intercept responses to handle errors
axiosInstance.interceptors.response.use(
  (response) => response, // Pass through successful responses
  (error) => {
    if (error.response.status === 401) {
      // Unauthorized error, clear user data and navigate to login
      store.dispatch(clearUserData()); // Clear user data in Redux store
      window.location.href = "/login"; // Redirect to login page
    }
    return Promise.reject(error); // Reject all other errors
  },
);

export default axiosInstance;
