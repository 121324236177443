import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer.types";
import { UserSliceState } from "./userSlice.types";

const initialState: UserSliceState = {
  data: null,
  token: null,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setToken: (state, { payload }: PayloadAction<string | null>) => {
      state.token = payload;
    },
    setData: (state, { payload }: PayloadAction<any>) => {
      state.data = payload;
    },
    clearUserData: (state) => {
      state.data = null;
      state.token = null;
    },
  },
});

export const userReducer = user.reducer;
export const userSelector = (state: RootState) => state.user;
export const { setToken, setData, clearUserData } = user.actions;
