export const flightCodes = [
  // Kazakhstan
  { code: "SCO", name: "Aktau International Airport" },
  { code: "ALA", name: "Almaty International Airport" },
  { code: "NQZ", name: "Astana International Airport" },
  { code: "GYD", name: "Atyrau International Airport" },
  { code: "KSN", name: "Kostanay Airport" },
  { code: "URA", name: "Oral Ak Zhol Airport" },
  { code: "PWQ", name: "Pavlodar International Airport" },
  { code: "PLX", name: "Semey International Airport" },
  { code: "CIT", name: "Shymkent International Airport" },
  { code: "UKK", name: "Ust-Kamenogorsk Airport" },

  // Turkey
  { code: "AYT", name: "Antalya Airport" },
  { code: "IST", name: "Istanbul Airport" },
  { code: "SAW", name: "Istanbul Sabiha Gökçen International Airport" },

  // CIS Region Capital Cities
  { code: "EVN", name: "Zvartnots International Airport (Yerevan, Armenia)" },
  {
    code: "GYD",
    name: "Heydar Aliyev International Airport (Baku, Azerbaijan)",
  },
  { code: "FRU", name: "Manas International Airport (Bishkek, Kyrgyzstan)" },
  { code: "TBS", name: "Tbilisi International Airport (Tbilisi, Georgia)" },
  { code: "KIV", name: "Chișinău International Airport (Chișinău, Moldova)" },
  { code: "MSQ", name: "Minsk National Airport (Minsk, Belarus)" },
  { code: "SVO", name: "Sheremetyevo International Airport (Moscow, Russia)" },
  { code: "DME", name: "Domodedovo International Airport (Moscow, Russia)" },
  { code: "VKO", name: "Vnukovo International Airport (Moscow, Russia)" },
  { code: "LED", name: "Pulkovo Airport (St. Petersburg, Russia)" },
  {
    code: "ASB",
    name: "Ashgabat International Airport (Ashgabat, Turkmenistan)",
  },
  {
    code: "TAS",
    name: "Tashkent International Airport (Tashkent, Uzbekistan)",
  },

  // China
  { code: "CAN", name: "Guangzhou Baiyun International Airport" },
];
