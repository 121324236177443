import axiosInstance from "./axiosConfig";

// Function to get the user's profile from the backend
export const getProfile = async () => {
  try {
    const response = await axiosInstance.get("/user/profile"); // Assuming '/user/profile' is the endpoint for fetching user profile
    return response.data; // Return the profile data from the response
  } catch (error) {
    console.error("Error fetching profile", error);
    throw error; // Propagate error to handle it in the calling function
  }
};
