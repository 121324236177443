import { combineReducers } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/userSlice";
import { globalReducer } from "./reducers/globalSlice";

const rootReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
});

export default rootReducer;
