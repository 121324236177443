export const getState = () => {
  const state = localStorage.getItem("state");
  return state ? JSON.parse(state) : undefined;
};

export const saveState = (state: any) =>
  localStorage.setItem("state", JSON.stringify(state));

export const clearState = () => {
  localStorage.removeItem("state");
};
