import React from "react";
import { NavLink } from "react-router-dom";

const BottomBar = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-gray-900 to-gray-700 text-white flex justify-around py-3 shadow-lg">
      {/* Send Order */}
      <NavLink
        to="/send-order"
        className={({ isActive }) =>
          isActive
            ? "text-blue-400"
            : "text-white hover:text-blue-300 transition duration-300 ease-in-out"
        }
      >
        <div className="flex flex-col items-center transform hover:scale-110 transition duration-300 ease-in-out">
          <i className="fas fa-paper-plane text-xl mb-1"></i>
          <span className="text-xs">Send</span>
        </div>
      </NavLink>

      {/* Deliver Order */}
      <NavLink
        to="/deliver-order"
        className={({ isActive }) =>
          isActive
            ? "text-blue-400"
            : "text-white hover:text-blue-300 transition duration-300 ease-in-out"
        }
      >
        <div className="flex flex-col items-center transform hover:scale-110 transition duration-300 ease-in-out">
          <i className="fas fa-inbox text-xl mb-1"></i>
          <span className="text-xs">Deliver</span>
        </div>
      </NavLink>

      <NavLink
        to="/add-order"
        className={({ isActive }) =>
          isActive
            ? "text-blue-400"
            : "text-white hover:text-blue-300 transition duration-300 ease-in-out"
        }
      >
        <div className="flex flex-col items-center transform hover:scale-110 transition duration-300 ease-in-out">
          <i className="fas fa-plus-circle text-xl mb-1"></i>
          <span className="text-xs">Add Order</span>
        </div>
      </NavLink>
      <NavLink
        to="/profile"
        className={({ isActive }) =>
          isActive
            ? "text-blue-400"
            : "text-white hover:text-blue-300 transition duration-300 ease-in-out"
        }
      >
        <div className="flex flex-col items-center transform hover:scale-110 transition duration-300 ease-in-out">
          <i className="fas fa-user text-xl mb-1"></i>
          <span className="text-xs">Profile</span>
        </div>
      </NavLink>
    </div>
  );
};

export default BottomBar;
