import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select"; // Import types from react-select
import { flightCodes } from "../const/flightCodes";

interface FlightOption {
  value: string;
  label: string;
}

const SearchForm: React.FC = () => {
  const [searchData, setSearchData] = useState({
    arrivalCity: "",
    destinationCity: "",
    date: new Date().toISOString(),
  });

  const [errors, setErrors] = useState({
    arrivalCity: "",
    destinationCity: "",
    date: "",
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setSearchData((prevState) => ({
      ...prevState,
      date: today,
    }));
  }, []);

  const flightOptions: FlightOption[] = flightCodes.map((flight) => ({
    value: flight.code,
    label: `${flight.code} - ${flight.name}`,
  }));

  // Correctly typed handleChange function
  const handleChange = (
    selectedOption: SingleValue<FlightOption>,
    { name }: { name: string },
  ) => {
    setSearchData({
      ...searchData,
      [name]: selectedOption ? selectedOption.value : "",
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      arrivalCity: "",
      destinationCity: "",
      date: "",
    };

    if (!searchData.arrivalCity) {
      newErrors.arrivalCity = "Please select an arrival city.";
      valid = false;
    }
    if (!searchData.destinationCity) {
      newErrors.destinationCity = "Please select a destination city.";
      valid = false;
    }
    if (!searchData.date) {
      newErrors.date = "Please select a date.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      navigate("/orders", { state: searchData });
    }
  };

  return (
    <div className="flex items-center justify-center px-4">
      <div className="card w-full max-w-xs sm:max-w-sm shadow-2xl bg-base-100">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">From</span>
              </label>
              <Select
                name="arrivalCity"
                options={flightOptions}
                value={flightOptions.find(
                  (option) => option.value === searchData.arrivalCity,
                )}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, { name: "arrivalCity" })
                }
                className={`select-bordered text-sm sm:text-base ${
                  errors.arrivalCity && "border-red-500"
                }`}
                placeholder="Select Arrival City"
                isSearchable={true}
              />
              {errors.arrivalCity && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.arrivalCity}
                </p>
              )}
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">To</span>
              </label>
              <Select
                name="destinationCity"
                options={flightOptions}
                value={flightOptions.find(
                  (option) => option.value === searchData.destinationCity,
                )}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, { name: "destinationCity" })
                }
                className={`select-bordered text-sm sm:text-base ${
                  errors.destinationCity && "border-red-500"
                }`}
                placeholder="Select Destination City"
                isSearchable={true}
              />
              {errors.destinationCity && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.destinationCity}
                </p>
              )}
            </div>

            <div className="form-control mb-5 sm:mb-6">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Date</span>
              </label>
              <input
                type="date"
                name="date"
                value={searchData.date}
                onChange={(e) =>
                  setSearchData({ ...searchData, date: e.target.value })
                }
                className={`input input-bordered text-sm sm:text-base ${
                  errors.date && "border-red-500"
                }`}
              />
              {errors.date && (
                <p className="text-red-500 text-sm mt-1">{errors.date}</p>
              )}
            </div>

            <div className="form-control mt-6">
              <button
                type="submit"
                className="btn bg-gradient-to-r from-blue-500 to-green-500 text-white w-full sm:w-auto py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
              >
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
