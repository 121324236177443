import axiosInstance from "./axiosConfig";

type RegistrationData = {
  email: string;
  telephone: string;
  name: string;
  password: string;
};

export const login = async (email: string, password: string) => {
  const response = await axiosInstance.post("/auth/login", {
    email,
    password,
  });
  return response.data;
};

export const registerUser = async (data: RegistrationData) => {
  const response = await axiosInstance.post("/auth/register", {
    ...data,
  });
  return response.data;
};
