import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../rootReducer.types";
import { GlobalSliceState } from "./globalSlice.types";

const initialState: GlobalSliceState = {
  currentHeaderTitle: "Главная",
  worksSearchValue: "",
  authFallbackLink: "",
};

const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    setCurrentHeaderTitle: (state, { payload }: PayloadAction<string>) => {
      state.currentHeaderTitle = payload;
    },
    setWorksSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.worksSearchValue = payload;
    },
    setAuthFallbackLink: (state, { payload }: PayloadAction<string>) => {
      state.authFallbackLink = payload;
    },
  },
});

export const globalReducer = global.reducer;
export const globalSelector = (state: RootState) => state.global;
export const {
  setCurrentHeaderTitle,
  setWorksSearchValue,
  setAuthFallbackLink,
} = global.actions;
