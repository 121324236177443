import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Select, { SingleValue } from "react-select"; // Import react-select and SingleValue type
import { flightCodes } from "../const/flightCodes";
import { create } from "../api/orders";
import { toast } from "react-toastify";

interface FlightOption {
  value: string;
  label: string;
}

const AddOrder = () => {
  const [formData, setFormData] = useState({
    author: "",
    date: "",
    from: "",
    to: "",
    weight: "",
    numberOfItems: "",
    price: "",
    orderType: "send",
    comment: "",
  });

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSelectChange = (
    selectedOption: SingleValue<FlightOption>,
    { name }: { name: string },
  ) => {
    setFormData({
      ...formData,
      [name]: selectedOption ? selectedOption.value : "",
    });
  };

  const addOrder = (data: any) => console.log("added order");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await create(formData);
      console.log(response);
      toast.success("Order added successfully!");
      navigate(
        formData.orderType === "send" ? "/send-order" : "/deliver-order",
      );
    } catch (error) {
      toast.error(
        error.response && error.response.data
          ? error.response.data.error || "An error occurred"
          : "An error occurred",
      );
      console.error("Error adding order:", error);
    }
  };

  React.useEffect(() => {
    const now = new Date();
    const offsetDate = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000,
    );
    const formattedDate = offsetDate.toISOString().slice(0, 16);
    setFormData((prevState) => ({
      ...prevState,
      date: formattedDate,
    }));
  }, []);

  const flightOptions: FlightOption[] = flightCodes.map((flight) => ({
    value: flight.code,
    label: `${flight.code} - ${flight.name}`,
  }));

  return (
    <div className="flex flex-col items-center justify-center mt-12 animate-fadeIn">
      <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-8">
        Add New Order
      </h1>
      <div className="card w-full max-w-xs sm:max-w-sm shadow-2xl bg-base-100">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  Order Type
                </span>
              </label>
              <div className="flex items-center space-x-4">
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="orderType"
                    value="send"
                    checked={formData.orderType === "send"}
                    onChange={handleChange}
                    className="radio radio-primary"
                  />
                  <span className="ml-2">Send</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="radio"
                    name="orderType"
                    value="deliver"
                    checked={formData.orderType === "deliver"}
                    onChange={handleChange}
                    className="radio radio-primary"
                  />
                  <span className="ml-2">Deliver</span>
                </label>
              </div>
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Date</span>
              </label>
              <input
                type="datetime-local"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">From</span>
              </label>
              <Select
                name="from"
                options={flightOptions}
                value={flightOptions.find(
                  (option) => option.value === formData.from,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, { name: "from" })
                }
                className="select-bordered text-sm sm:text-base"
                placeholder="Select Departure City"
                isSearchable={true} // Enables search
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">To</span>
              </label>
              <Select
                name="to"
                options={flightOptions}
                value={flightOptions.find(
                  (option) => option.value === formData.to,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, { name: "to" })
                }
                className="select-bordered text-sm sm:text-base"
                placeholder="Select Arrival City"
                isSearchable={true} // Enables search
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  Weight (kg)
                </span>
              </label>
              <input
                type="number"
                name="weight"
                value={formData.weight}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder="Enter weight in kg"
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">
                  Number of Items
                </span>
              </label>
              <input
                type="number"
                name="numberOfItems"
                value={formData.numberOfItems}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder="Enter number of items"
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Price</span>
              </label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder="Enter price in Tenge"
                required
              />
            </div>

            <div className="form-control mb-3 sm:mb-4">
              <label className="label">
                <span className="label-text text-sm sm:text-base">Comment</span>
              </label>
              <input
                type="text"
                name="comment"
                value={formData.comment}
                onChange={handleChange}
                className="input input-bordered text-sm sm:text-base"
                placeholder="Enter comment"
              />
            </div>

            <div className="form-control mt-6">
              <button
                type="submit"
                className="btn bg-gradient-to-r from-blue-500 to-green-500 text-white w-full sm:w-auto py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
              >
                Add Order
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="h-32"></div>
    </div>
  );
};

export default AddOrder;
