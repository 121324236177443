import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { IOrder } from "../types/Order";
import { fetch } from "../api/orders";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { userSelector } from "../redux/reducers/userSlice";
import { useSelector } from "react-redux";

const OrdersList: React.FC<{
  passedFilters?: any;
  isSearchVisible?: boolean;
}> = ({ passedFilters, isSearchVisible = true }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const filters = location.state || passedFilters;

  const [orders, setOrders] = React.useState<IOrder[]>([]); // Default to empty array
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [visiblePhone, setVisiblePhone] = React.useState<{
    [key: string]: boolean;
  }>({}); // Track phone visibility by order ID

  const { data: userData } = useSelector(userSelector);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        setLoading(true);
        setError(""); // Reset error state before fetching
        const data = await fetch(filters);
        setOrders(data.orders || []); // Fallback to an empty array if no data is returned
      } catch (err) {
        console.error(err);
        setError("Failed to fetch orders");
        setOrders([]); // Set to an empty array in case of error
      } finally {
        setLoading(false); // Stop loading after fetching
      }
    };

    if (!loading) fetchOrders();
  }, [filters]); // Re-run the effect only if filters change

  // Toggle phone visibility
  const showPhone = (orderId: string) => {
    if (visiblePhone[orderId]) return;

    setVisiblePhone((prev) => ({
      ...prev,
      [orderId]: true,
    }));

    ReactGA.event({
      category: "Order",
      action: "Phone Reveal",
      label: `Order ${orderId}, user ${userData?.name}`,
    });
  };

  // Handle loading state
  if (loading) {
    if (!isSearchVisible)
      return (
        <div className="flex h-screen animate-fadeOut">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 mt-8"></div>
        </div>
      );
    return (
      <div className="flex justify-center items-center h-screen animate-fadeOut">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
      </div>
    );
  }

  // Handle error state
  if (error) {
    toast.error(`Error while loading orders: ${error}`);
    return <p className="text-center text-red-500">{error}</p>;
  }

  // Render the orders with fade-in animation
  return (
    <div className="container mx-auto flex flex-col items-center mt-12 min-h-screen px-4 animate-fadeIn">
      {isSearchVisible && (
        <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-8">
          Orders
        </h1>
      )}
      <div className="flex flex-col items-center">
        {orders.length === 0 ? (
          isSearchVisible ? (
            <p className="text-center text-gray-500 mt-4">
              No orders found. Please try a different search.
            </p>
          ) : (
            <></>
          )
        ) : (
          orders.map((order: IOrder) => (
            <div
              key={order._id}
              className="card w-full max-w-xs sm:max-w-lg shadow-2xl bg-base-100 mb-3 sm:mb-4"
            >
              <div className="card-body">
                <div className="flex flex-col items-center sm:items-start">
                  <div className="text-xl sm:text-3xl font-extrabold tracking-wider text-primary flex items-center space-x-2 mt-2">
                    <span>{order.from}</span>
                    <span className="text-gray-500">
                      <i className="fas fa-plane"></i>
                    </span>
                    <span>{order.to}</span>
                  </div>
                  <div className="flex flex-col sm:flex-row justify-between w-full mt-4 text-base sm:text-lg font-medium">
                    <div className="mb-3 sm:mb-0">
                      <p className="text-accent flex items-center">
                        <i className="fas fa-user mr-2"></i> {order.author.name}
                      </p>
                      <button
                        onClick={() => showPhone(order._id)} // Toggle phone visibility
                        className="text-base sm:text-lg text-gray-700 flex items-center hover:text-blue-500"
                      >
                        <i className="fas fa-phone mr-2"></i>
                        {visiblePhone[order._id]
                          ? order.author.telephone // Show phone number if toggled on
                          : "Show Phone"}
                      </button>
                      <p className="text-gray-600 flex items-center">
                        <i className="fas fa-calendar-alt mr-2"></i>{" "}
                        {format(new Date(order.date), "MMMM dd, yyyy, HH:mm")}
                      </p>
                    </div>
                    <div className="text-left sm:text-right">
                      <p className="text-base sm:text-lg text-gray-700 flex items-center">
                        <i className="fas fa-weight mr-2"></i> {order.weight} kg
                      </p>
                      <p className="text-base sm:text-lg text-gray-700 flex items-center">
                        <i className="fas fa-suitcase mr-2"></i>{" "}
                        {order.numberOfItems} Items
                      </p>
                      {order.comment && (
                        <p className="text-base sm:text-lg text-gray-700 flex items-center">
                          <i className="fas fa-comment mr-2"></i>{" "}
                          {order.comment}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end w-full mt-4">
                    <p className="text-right text-xl sm:text-2xl font-bold text-primary">
                      ₸{order.price}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
        {isSearchVisible && (
          <button
            onClick={() => navigate(-1)}
            className="btn bg-gradient-to-r from-blue-500 to-green-500 text-white w-full sm:w-auto py-2 px-4 rounded-full hover:shadow-lg transform hover:scale-105 transition duration-300"
          >
            New Search
          </button>
        )}
      </div>
    </div>
  );
};

export default OrdersList;
