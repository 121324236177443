import React, { useMemo } from "react";
import SearchForm from "./SearchForm";
import OrdersList from "./OrdersList";

const SearchPage: React.FC<{ orderType: any }> = ({
  orderType,
}: {
  orderType: "send" | "deliver";
}) => {
  // Use useMemo to memoize filters
  const filters = useMemo(
    () => ({
      limit: 2,
      orderBy: "date",
      sortOrder: "desc",
      orderType,
    }),
    [orderType],
  );

  return (
    <div className="container mx-auto flex flex-col items-center mt-12 min-h-screen px-4 animate-fadeIn">
      <h1 className="text-2xl sm:text-4xl font-extrabold text-center mb-4 sm:mb-8">
        {orderType === "send" ? "Send Orders List" : "Deliver Orders List"}
      </h1>
      <SearchForm />

      <OrdersList passedFilters={filters} isSearchVisible={false} />

      <div className="h-32"></div>
    </div>
  );
};

export default SearchPage;
